@import "../../base/styles/colors";
@import "../../base/styles/mixins/media-queries.scss";

.singleDatePicker .DayPicker {
	&-wrapper,
	&-wrapper:active,
	&-wrapper:focus {
		outline: 0;
	}

	&-Caption {
		margin-bottom: 20px;
		@include screen-max(730) {
			margin-left: 12px;
		}
		@include screen-max(680) {
			margin-left: 22px;
		}
	}

	&-Caption > div {
		font-size: 1.4rem;
	}

	&-NavBar {
		display: none;
	}

	&-Day {
		position: relative;
		padding: 0.5em 19px;
		background: none !important;
		border-radius: 0;

		@media (max-width: 900px) and (min-width: 767px) {
			padding: 0.5em 1.8vw;
		}

		@media (max-width: 450px) {
			padding: 0.5em 3.5vw;
		}

		&::before {
			position: absolute;
			top: 0;
			left: calc(50% - 17.5px);
			z-index: -1;
			width: 35px;
			height: 35px;
			content: "";
			background: none;
			transition: background 0.3s ease-out;
		}

		&:hover::before {
			background: rgba(84, 191, 205, 0.3);
		}

		&:active {
			transform: translateY(1px);
			transition-duration: 0.05s;
		}

		&--outside {
			pointer-events: none;
		}

		&--today {
			color: inherit;
			font-weight: inherit;
		}

		&--selected {
			font-weight: 800;
		}

		&--selected::before,
		&--selected:hover::before,
		&:active::before {
			background: $cta;
		}
	}
}
