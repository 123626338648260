@import "../../../base/styles/colors.scss";

.completeProfile {
	overflow-y: scroll;
	.panel {
		@media (max-width: 767px) {
			padding: 0;
			background: none;
		}
	}

	&__title {
		font-size: 2.8rem;
		line-height: 1.07;
		color: $light-blue-grey;
		font-weight: bold;
	}

	&__gender {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		padding-top: 23px;
	}

	&__pic {
		width: 36px;
		height: 36px;
		bottom: 20px;
		display: inline-flex;
		vertical-align: middle;
		background: $link;
		font-size: 2rem;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		position: absolute;
		border-radius: 50%;
		border: 2px solid #fff;

		right: 0px; // same width of the picture to set it on the right
		z-index: 1;
	}

	&__link-account {
		margin: 26px 0 64px 0;
	}

	&__description {
		padding: 20px 0 30px 0;
	}

	&__subtitle {
		font-size: 2rem;
		font-weight: bold;
		font-family: "Akrobat", sans-serif;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	&__unlink-btn {
		width: 90px;
		height: 42px;
		font-size: 1.2rem;
	}

	&__group {
		margin-bottom: 20px;
	}

	&__holder {
		position: relative;
	}

	&__change {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 20px;
		color: $link;
	}

	&__holder:active &__change {
		transform: translateY(1px);
	}

	&__dp {
		position: relative;
		width: 100px;
		height: 100px;
		margin: 24px 0;
		background: url(../../../img/icons/person.svg) no-repeat #f3f3f3 50%/50% auto;
		cursor: pointer;

		&-container {
			width: inherit;
			height: inherit;
			font-size: 3rem;

			display: flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
		}

		&:hover::after {
			opacity: 1;
		}

		&:active {
			transform: scale(0.96);
			transition-duration: 0.05s;
		}

		&.isLoading {
			background: #fff;
		}

		&-error {
			justify-content: center !important;
			margin: -15px auto 60px;
		}
	}

	&__checkbox {
		margin-right: 20px;
	}

	&__datepicker {
		position: absolute;
		top: calc(100% - 1px);
		left: 15px;
		z-index: 25;
		width: calc(100% - 30px);
		padding: 15px 10px;
		font-size: 1.4rem;
		background: #fff;
		box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1),
			2px 2px 20px rgba(0, 0, 0, 0.05);
		border: solid 1px #54bfcd;
	}

	.DayPicker {
		&-Day {
			position: relative;
			padding: 0.5em 18px;
		}
	}

	&__divider {
		height: 1px;
		margin: 0 -40px 25px;
		background: #eee;
	}

	&__btn {
		float: right;
		max-width: 240px;

		@media (max-width: 767px) {
			float: none;
			width: 100%;
			max-width: none;
		}
	}
}

.completeProfile {
	&__error {
		margin: 10px 0 40px;
	}

	&__mandatory {
		margin-bottom: 30px;
		color: #000;
		font-size: 1.4rem;
	}

	&__form-left-column {
		&,
		& + div {
			padding-right: 10px !important;
		}
	}

	&__gender-selection {
		margin-bottom: 29px;
	}
}

.form__group {
	margin-bottom: 6px;
}

.district-select {
	outline: none;
	border: 0;

	&-row {
		z-index: 50;
	}

	&-indicator {
		height: 28px;
		svg {
			fill: #59baae;
		}
	}

	&-option {
		padding: 10px;
		&.active,
		&:hover {
			background: #9baca2;
			color: #fff;
		}
	}

	& > div {
		border: 0;
		outline: none !important;
		box-shadow: none;
		padding: 0 10px 10px;
		font-family: "Inter", sans-serif;
	}
}
