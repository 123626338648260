//default base colour
$base: #323232;
$secondary: #aabbb9;
$theme-bg: #f4f4f4;
$secondary-headline-color: #8ab0ab;
//for cta buttons
// $cta: #74b8ae;
$light-dark: #232323;
$cta: #3e505b;
//for links
$link: #59baae;
//primary colour for various copy that needs emphasis
$primary: #1f455f;
// light colour
$light: #666;
$label-light: #9baca2;
// for heading
$slate: #4f7477;
$dark-slate-blue: #1f455f;
//states
$error: #e40621;
$success: #155724;
$placeholder: #999;
$placeholder-error: #bc6767;
$carousel-dot-active: #1f455f;
// for studio card texts
$slate: #4f7477;
// for studio detail text
$warm-grey: #9b9b9b;
$dusty-teal: #54939a;
$turquoise-blue: #00c3cf;
$cherry-red: #e40621;
$blue-grey: #8ab2b3;
$light-blue-grey: #8ab0ab;
$orange: #f9b576;
$mariner: #3b5998;
//
$type-face-color: #9baca2;
$light-grey-text: #999999;
$light-grey-bg: #dddddd;
$ligth-teal-text: #8ab0ab;

// fonts
$inter-font: "Inter", Sans-Serif;
$acrobat-font: "Akrobat", Sans-Serif;
