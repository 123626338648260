@mixin styled-scrollbar($interact: false) {
	$scrollbarGap: 7px;
	$scrollbarWidth: 6px;
	$scrollbarSize: $scrollbarWidth + ($scrollbarGap * 2);

	scroll-behavior: smooth;

	// scrollbar visible only on interact
	@if ($interact) {
		visibility: hidden;

		&:focus,
		&:hover {
			visibility: visible;
		}

		> * {
			visibility: visible;
		}
	}

	&::-webkit-scrollbar {
		width: $scrollbarSize;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 30px;

		box-shadow: inset 0 0 $scrollbarWidth $scrollbarWidth #aabbb9;
		border: solid $scrollbarGap transparent;
	}
}

@mixin word-wrap() {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
