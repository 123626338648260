//generic reusable animations
@charset 'utf-8';
@import "./mixins/str-replace";

@keyframes shake {
  0% {
    transform: translateX(0px);
  }

  12.5% {
    transform: translateX(-12px);
  }

  37.5% {
    transform: translateX(12px);
  }

  50% {
    transform: translateX(0px);
  }

  62.5% {
    transform: translateX(-5px);
  }

  87.5% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes from-top {
  0% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes from-bottom {
  0% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes from-left {
  0% {
    transform: translateX(-50px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes from-right {
  0% {
    transform: translateX(50px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes to-right {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(50px);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes expand {
  0% {
    padding: 0px;
  }

  100% {
    padding: 3px;
  }
}

@for $i from 1 through 10 {
  .animated-delay--#{str-replace($i / 2+"", ".", "-")} {
    animation-delay: $i / 20 + s !important;
  }

  .transition-delay--#{str-replace($i / 2+"", ".", "-")} {
    transition-delay: $i / 20 + s !important;
  }
}

@keyframes vertically-scale-up {
  0% {
    transform: sclaeY(0);
  }

  25% {
    transform: scaleY(0.25);
  }

  50% {
    transform: scaleY(0.5);
  }

  75% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(1);
  }
}

.animation-workaround {
  animation: shake .1s, fade-in .1s, fade-out .1s, from-top .1s, from-bottom .1s, from-left .1s, from-right .1s, to-right .1s, bounce .1s, expand .1s, vertically-scale-up .1s ;
}
