.selectDropDown {
	position: relative;

	&__label {
		font-size: 1.2rem;
		text-transform: uppercase;
		font-weight: 400;
		color: #9baca2;
		margin-bottom: 0.5rem;
		font-family: "Inter", sans-serif;
		position: absolute;
		z-index: 10;
		left: 15px;
		top: 10px;
	}
  &__labelNew {
		font-size: 1.2rem;
		text-transform: uppercase;
		font-weight: 400;
		color: #9baca2;
		margin-bottom: 0.5rem;
		font-family: "Inter", sans-serif;
		position: absolute;
		z-index: 10;
		left: 10px;
		top: 10px;
	}
}
