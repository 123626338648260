.footerApps {
	&__icon {
		&--apple {
			background: url(../../../img/ui/logo-apple-green.svg) no-repeat center /
				contain;
		}

		&--google {
			background: url(../../../img/ui/logo-google-play-green.svg) no-repeat
				center / contain;
		}
	}
}
