@import "../../../../base/styles/colors";

.friendsModalList {
	&--busy {
		opacity: 0.5;
		pointer-events: none;
	}

	&__button {
		width: 100px;
	}

	&__heading {
		color: $secondary-headline-color;
		font-size: 1.4rem;
		font-family: "Akrobat", sans-serif;
		text-transform: uppercase;
		margin-bottom: 12px;
		font-weight: bold;
	}

	&__requested {
		background: $orange;
		display: inline-block;
		font-size: 1.4rem;
		font-weight: bold;
		font-family: "Akrobat", sans-serif;
		margin-left: auto;
		padding: 3px 10px;
		text-transform: uppercase;
		cursor: pointer;
	}

	&__search {
		margin-bottom: 20px;
	}

	&__list {
		height: calc(100% - 345px);
		overflow-y: auto;
	}
	// 	width: calc(100% + 80px);
	// 	margin: 30px -40px -40px;

	// 	@media (max-width: 767px) {
	// 		width: calc(100% + 40px);
	// 		margin: 30px -20px -40px;
	// 	}
	// }

	&__item {
		margin-bottom: 5px;

		&-contents {
			background: #fff;
			padding: 12px 20px;
		}

		&:last-child {
			margin-bottom: 20px;
		}

		@media (max-width: 767px) {
			// padding: 7px 0px;
		}

		&-pic {
			width: 48px;
			height: 48px;
			background: #aabbb9;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			font-size: 1.6rem;
			color: #232323;
		}

		&:active &-pic {
			transform: scale(0.95);
			transition-duration: 0.05s;
		}

		&-name {
			width: auto;
			padding: 0 20px;
			text-align: left;
			font-size: 1.6rem;
			font-weight: bold;
			text-transform: capitalize;
		}

		&:hover &-name {
			color: $cta;
		}

		> span {
			width: calc(100% - 50px);
		}

		&-checkbox {
			pointer-events: none;
		}

		.checkbox__icon {
			width: 20px;
			height: 20px;
			margin-right: 0;
		}
	}

	&__empty {
		width: 100%;
		height: 300px;
		padding-bottom: 15px;
		padding-top: 2.4rem;

		&-icon {
			width: 60px;
			height: auto;
			margin-bottom: 10px;
		}
	}
}
