@mixin screen-max($max-width) {
	@media only screen and (max-width: $max-width) {
		@content;
	}
}

@mixin screen-min($min-width) {
	@media only screen and (min-width: $min-width) {
		@content;
	}
}
