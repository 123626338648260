.headerNavIcon {
  position: absolute;
  top: calc(50% - 10px);
  left: 20px;
  display: block;
  width: 23px;
  height: 20px;
  background: none;
  border: 0;
  outline: 0;

  @media (min-width:992px) {
    display: none;
  }

  &:focus {
    outline: 0;
  }

  &__top,
  &__mid,
  &__btm {
    position: absolute;
    left: 50%;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    transform: translateX(-50%);
    pointer-events: none;

    &::before {
      display: block;
      width: 100%;
      height: 100%;
      content: "";
      background: #231f20;
    }

  }

  &__top {
    top: 0;
  }

  &__mid {
    top: calc(50% - 1px);
  }

  &__btm {
    bottom: 0;
  }

  &--active &__top,
  &--active &__btm {
    transform: translateX(-50%);
  }

  &--active &__top {
    top: calc(50% - 1px);

    &::before {
      transform: rotate(45deg);
    }

  }

  &--active &__mid {
    opacity: 0;
  }

  &--active &__btm {
    bottom: calc(50% - 1px);

    &::before {
      transform: rotate(-45deg);
    }

  }

}
