.footerCopyright {
	position: relative;
	width: 100%;
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	margin-top: 32px;
	@media (max-width: 767px) {
		flex-direction: column;
	}

	&__social {
		@media (min-width: 768px) {
			margin-left: auto;
		}
	}

	&__col--left,
	&__col--right {
		position: absolute;

		@media (max-width: 992px) {
			position: static;
		}
	}

	&__col {
		display: flex;
		margin-right: 20px;
		font-size: 1.4rem;

		@media (max-width: 992px) {
			justify-content: center;
			text-align: center;
			margin-right: 0;
		}

		@media (max-width: 767px) {
			margin-top: 15px;
		}
	}

	&__col--right {
		right: 0;
		text-align: right;

		@media (max-width: 992px) {
			margin: 10px 0;
			text-align: center;
		}
	}

	&__col--center {
		color: #aaa;
	}

	&__link {
		margin-right: 25px;
		font-size: 1.4rem;
		display: inline-block;
		color: #3e505b;
	}

	&__logo--visa {
		width: 60px;
	}

	&__logo--mastercard {
		margin-left: 15px;
	}
}
