@import "./colors.scss";

.color-black {
	color: #000;
}

.ymTitle {
	font-family: "Akrobat", Sans-Serif;
	&--bold {
		font-weight: bold;
	}
}

.secondary-head-line {
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: $secondary-headline-color;
	font-family: "Akrobat", Sans-Serif;
}

.price-wrapper {
	font-family: "Akrobat", sans-serif;
}
