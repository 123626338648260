@import "../../../../base/styles/colors.scss";
.newbie-payment-option {
	padding: 16px 20px;
	background: #fff;
	margin-bottom: 6px;
	cursor: pointer;
	position: relative;
	color: #8ab0ab;
	&:last-of-type {
		margin-bottom: 0;
	}

	&--has-tag {
		padding-top: 22px;
	}

	&--special {
		background: #000;
		&,
		.newbie-payment-option__row__2 {
			color: #fff !important;
		}
	}

	&--soldout {
		background-color: #ddd;
		box-shadow: none !important;
		color: #999;
		cursor: normal;
	}

	&__tag {
		background: #f7a754;
		text-transform: uppercase;
		padding: 2px 11px;
		position: absolute;
		right: 20px;
		top: 0;
		font-weight: bold;
		font-size: 1.1rem;
		color: #000;
		font-family: "Akrobat", sans-serif;
	}

	&__row {
		&__1 {
			display: flex;
			align-items: start;
		}
		&__2 {
			font-family: Inter;
			font-size: 1.4rem;
			line-height: 1.29;
			text-align: right;
			color: #232323;
		}
	}
	&__title {
		font-family: Akrobat, sans-serif;
		font-size: 2.4rem;
		font-weight: bold;
		text-transform: uppercase;
		padding-right: 12px;
	}
	&__price {
		font-family: Akrobat, sans-serif;
		font-size: 2.4rem;
		font-weight: bold;
		text-align: right;
		margin-left: auto;
	}
	&__checked {
		margin-top: 8px;
		width: 18px;
		height: 18px;
		object-fit: contain;
		margin-right: 10px;
	}
	.validityMonth {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		.info {
			padding: 0 10px;
			width: fit-content;
			height: 25px;
			display: inline-flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			font-size: 14px;
			font-weight: bold;
			font-family: "Akrobat", sans-serif;
			color: #ffffff;
			background-color: $light-blue-grey;
			text-transform: uppercase;
			margin-top: 8px;
		}
	}
}
