@import '../../../base/styles/colors';

.successMessage {
  margin: 10px 0;
  line-height: 1.2em;

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: url(../../../img/icons/tick-white.svg) no-repeat #000 center / 42% auto;
    border-radius: 100%
  }

  &__contents {
    max-width: calc(100% - 34px);
  }

}
