.apple-signin-auth-btn {
	width: 100% !important;
	height: 50px;
	border: none;
	position: absolute;
	left: 0;
	top: -15px;
	color: #fff;
	background-color: transparent !important;
	&:focus {
		border: none;
		outline: none;
	}
	&:hover {
		border: none;
		outline: none;
	}
	svg {
		display: none;
	}
}
