.appleSignInModal {
	padding: 30px;
	text-align: center;

	.description {
		margin: 20px 0 40px;
	}

	.loader {
		padding-bottom: 30px;
	}
}
