@import "./mixins/fonts";

@include font-face("Inter", '../../../../public/assets/fonts/Inter-Thin', 100, normal, woff2 woff);

// @include font-face(
// 	"Inter",
// 	"../../../../public/assets/fonts/Inter-Ultralight",
// 	200,
// 	normal,
// 	woff2 woff
// );

@include font-face("Inter", "../../../../public/assets/fonts/Inter-Light", 300, normal, woff2 woff);
@include font-face(
	"Inter",
	"../../../../public/assets/fonts/Inter-Regular",
	normal,
	normal,
	woff2 woff
);
@include font-face("Inter", "../../../../public/assets/fonts/Inter-Medium", 500, normal, woff2 woff);
// @include font-face("Inter", "../../../../public/assets/fonts/Inter-Semibold", 600, normal, woff2 woff);
@include font-face("Inter", "../../../../public/assets/fonts/Inter-Bold", 700, normal, woff2 woff);
// @include font-face("Inter", "../../../../public/assets/fonts/Inter-Heavy", 800, normal, woff2 woff);
@include font-face("Inter", "../../../../public/assets/fonts/Inter-Black", 900, normal, woff2 woff);

@include font-face(
	"Akrobat",
	"../../../../public/assets/fonts/Akrobat-Regular",
	400,
	normal,
	woff2 woff
);
@include font-face(
	"Akrobat",
	"../../../../public/assets/fonts/Akrobat-Regular",
	400,
	italic,
	woff2 woff
);

@include font-face("Akrobat", "../../../../public/assets/fonts/Akrobat-SemiBold", 600, normal, ttf);
@include font-face("Akrobat", "../../../../public/assets/fonts/Akrobat-Bold", 700, normal, woff2 woff);
@include font-face(
	"Akrobat",
	"../../../../public/assets/fonts/Akrobat-Bold",
	bold,
	normal,
	woff2 woff
);
