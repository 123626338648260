@import "../../../base/styles/mixins/media-queries.scss";
@import "../../../base/styles/colors";

.FooterInstagram {
	background: #f4f4f4;
	padding: 17px;
	display: flex;
	align-items: flex-start;

	@include screen-max(550) {
		align-items: center;
		padding: 15px;
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: -25px;
	}

	&__logo {
		margin-right: 16px;
		width: 86px;
		height: 86px;
	}
	&__text {
		padding-top: 10px;
		font-family: "Akrobat", sans-serif;
		color: #232323;
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;

		@include screen-max(550) {
			font-weight: 600;
			padding-top: 0;
		}
	}
	&__button {
		color: $link;
		background: #fff;
		border: 0.5px solid #59baae;
		border-radius: 2px;
		min-width: 76px;
		padding: 2px 10px;
		font-weight: bold;
		text-align: center;
		margin-top: 10px;

		@include screen-max(550) {
			margin-top: 8px;
		}
	}
}
