@import "../../../../base/styles/colors";
@import "../../../../base/styles/mixins/media-queries.scss";

/** most styles are inherited from Authentication.scss in parent to maintain consistent look & feel */
.countryModal {
	// width: 400px;
	// height: 382px;

	border-radius: 0px;
	height: fit-content;
	padding-bottom: 4.6rem !important;
	.icon {
		margin: 0px auto;
		text-align: center;
	}
	.lang__contents {
		width: 415px;
		height: 100%;
		@include screen-max(900) {
			width: 100%;
		}
		overflow: hidden;
		scroll-behavior: smooth;
	}
	.title {
		font-family: "Akrobat";
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 10px;
		/* identical to box height */
		margin-top: 40px;
		text-align: center;
		text-transform: uppercase;
		color: #272727;
		text-align: center;
		text-transform: uppercase;
		color: #272727;
	}
	.container {
		width: 100%;
		padding: 0px 40px;
		@include screen-max(900) {
			padding: 0px 28px;
		}
		display: flex;
		flex-direction: column;

		.langBox {
			display: flex;
			width: 100%;
			height: 43px;
			margin-top: 20px;

			justify-content: space-between;
			align-items: center;
			align-content: center;
			.boxLeft {
				display: flex;

				cursor: pointer;
				._desc {
					margin-left: 15px;
					.title {
						font-family: "Akrobat";
						font-style: normal;
						font-weight: 700;
						font-size: 20px;
						line-height: 25px;
						/* identical to box height */
						text-transform: uppercase;
						color: #232323;
					}
					.lang {
						font-family: "Inter";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 18px;
						/* identical to box height, or 129% */
						color: #232323;
					}
				}
			}
			.boxRight {
				display: flex;
				justify-content: space-between;
				cursor: pointer;
			}
			._icon {
				width: 42px;
				height: 31px;
				margin-top: 4px;
				img {
					width: 100%;
					height: 100%;
				}
				justify-content: center;
				display: flex;
				align-items: center;
				cursor: pointer;
				svg {
					vertical-align: middle;
					height: 31px !important;
					width: 42px !important;
				}
			}
		}
	}
	.content {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		/* or 138% */
		text-align: center;
		color: #272727;
	}
	._btnWrapper {
		height: 49px;
		margin: 0px auto;
		width: 360px;
		display: flex;
		justify-content: center;
		margin-top: 10px;
		align-items: center;
		align-content: center;
		background: #3e505b;
		border-radius: 24px;
		.__btn {
			text-align: center;
			font-family: "Inter";
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
			text-align: center;

			color: #ffffff;
		}
	}
	&__or {
		margin-bottom: 40px;
		@media (max-width: 992px) {
			margin-bottom: 20px;
		}
	}
}
