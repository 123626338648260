.resetPasswordModal {
	padding-bottom: 30px;

	&__tip {
		margin-bottom: 20px;
		color: #999;
	}

	&__error {
		margin: 20px 0 -10px;
	}

	&__save {
		width: 128px;
		margin: 0px 25px 0px auto;
	}

	&__title {
		margin-bottom: 30px;
		font-size: 2.8rem;
		text-transform: uppercase;
		font-family: "Akrobat", sans-serif;
		text-align: center;
	}

	&__field {
		position: relative;
	}

	&__toggle {
		position: absolute;
		right: 0;
		top: 3px;
		height: 100%;
		padding: 0 23px;
		background: none;
		height: auto;
		border: 0;
		font-size: 1.2rem;
		font-weight: bold;
	}
}
