@import "../../../base/styles/mixins/utilities.scss";

.newbie-payment-modal {
	&__content {
		display: grid;
		grid-template-columns: minmax(0, 1fr) minmax(0, 1.1fr);

		@media (max-width: 992px) {
			grid-template-columns: minmax(0, 1fr);
		}

		max-height: calc(100% - (80px * 2));
		overflow: auto;
		@include styled-scrollbar();
	}

	&__left {
		padding: 20px 30px;
		background: #fff;

		@media (max-width: 767px) {
			padding: 20px;
		}

		&__title {
			font-family: Akrobat, sans-serif;
			font-size: 3.6rem;
			line-height: 1.67;
			color: #232323;
			text-transform: uppercase;
		}

		&__description {
			margin-top: 12px;
			font-family: Inter, sans-serif;
			font-size: 1.4rem;
			line-height: 1.29;
			color: #232323;
		}

		&__packs-include {
			margin-top: 30px;
			display: flex;
			align-items: center;
			&__icon {
				width: 40px;
				height: 40px;
				background: url(../../../img/icons/newbie-icon.svg) center no-repeat;
			}
			&__text {
				margin-left: 12px;
				font-family: Akrobat, sans-serif;
				font-size: 2rem;
				font-weight: bold;
				color: #8ab0ab;
				text-transform: uppercase;
			}
			&__description {
				margin-top: 19px;
				font-family: Inter, sans-serif;
				line-height: 1.38;
				color: #232323;
				display: grid;
				grid-template-columns: 1fr;
				gap: 5px;
			}
		}
	}

	&__right {
		padding: 25px 30px;

		@media (max-width: 767px) {
			padding: 20px;
		}
		&__title {
			font-family: Inter, sans-serif;
			font-weight: bold;
			line-height: 1.38;
			color: #232323;
		}
		&__description {
			margin: 4px 0 20px;
			font-family: Inter;
			font-size: 1.4rem;
			line-height: 1.29;
			color: #232323;
		}
		&__or {
			margin-top: 20px;
			margin-bottom: 20px;
			font-family: Akrobat, sans-serif;
			font-size: 2rem;
			font-weight: bold;
			color: #232323;
		}
		&__actions {
			margin-top: 40px;

			@media (min-width: 769px) {
				display: flex;
				justify-content: space-between;
			}
			&__text {
				font-family: Inter, sans-serif;
				color: #59baae;
				margin-bottom: 6px;
				cursor: pointer;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			&__button {
				max-width: 150px;
			}
		}
	}
}
