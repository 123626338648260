@import "../../base/styles/mixins/media-queries.scss";
@import "../../base/styles/colors";

.AppDownload {
	display: flex;
	background: $secondary;
	justify-content: stretch;
	position: relative;
	max-width: 100%;

	&__inner {
		width: calc(100% - 60px);
		max-width: 1280px;
		margin: 0 auto;
		position: relative;
		padding: 45px 0;
	}

	@media (max-width: 992px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 15px 20px;
		padding-bottom: 0;
	}

	@include screen-max(550) {
		padding: 24px 29px;
		padding-bottom: 0;
		text-align: left;
	}

	&__store-link {
		width: 180px !important;
		display: inline-block;

		@include screen-max(992) {
			margin-bottom: 10px;
		}
	}

	&__title {
		font-size: 4.8rem;
		line-height: 1;
		margin-bottom: 10px;
		color: #fff;
		text-transform: uppercase;

		@include screen-max(550) {
			font-size: 28px;
			margin-bottom: 4px;
		}
	}

	&__description {
		max-width: 268px;
		font-size: 1.6rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #232323;
		margin-bottom: 15px;
		@media (max-width: 992px) {
			margin-left: auto;
			margin-right: auto;
		}

		@include screen-max(550) {
			font-size: 12px;
			margin: 0;
			margin-bottom: 20px;
		}
	}

	&__wrapper {
		position: absolute;
		right: 80px;
		bottom: 0;
		img {
			max-width: 400px;
		}

		@media (max-width: 992px) {
			position: static;
			text-align: center;
			img {
				max-width: 280px;
			}
		}

		@include screen-max(550) {
			text-align: left;
		}
	}

	&__appLinks {
		display: grid;
		grid-template-columns: auto auto;
		grid-gap: 1rem;

		a {
			display: block;
		}

		img {
			display: block;
			height: auto;
			width: 100%;
			max-width: 180px;
		}

		@include screen-max(550) {
			grid-template-columns: auto;
		}
	}
}
