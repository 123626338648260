.friendsModal {
	padding-top: 25px;
	padding-bottom: 60px;
	min-height: 320px;

	&__title {
		font-size: 2.4rem;
		text-align: center;
		font-family: "Akrobat", sans-serif;
		text-transform: uppercase;
		font-weight: normal;
		color: #232323;
	}

	&__content {
		padding: 30px;
		height: inherit;

		@media (max-width: 767px) {
			padding: 15px;
		}
	}
}

.overlay__inner {
	overflow: hidden;
}
