@import "../../../base/styles/colors";

.schedulesListRow {
	&__mobile {
		padding: 16px 20px;
		background-color: #fff;
		margin-bottom: 6px;
		display: grid;
		grid-template-columns: 1fr 96px;

		&__actions {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__title {
			font-family: "Akrobat", sans-serif;
			font-weight: bold;
			font-size: 2rem;
			color: #232323;
			line-height: 20px;
			text-transform: uppercase;
		}

		&__time {
			margin-top: 9px;
			display: flex;
			align-items: center;
		}

		&__icon {
			width: 16px;
			height: 16px;
			background: no-repeat center;
		}

		&__description {
			margin-left: 6px;
			font-family: "Inter", Sans-Serif;
			font-size: 1.2rem;
			line-height: 16px;
			color: #232323;
		}

		&__place-instructor {
			margin-top: 6px;
			display: inline-grid;
			grid-template-columns: auto auto;
			gap: 20px;
		}

		&__place,
		&__instructor {
			display: inline-flex;
			align-items: center;
		}
	}

	position: relative;
	display: table-row;
	cursor: pointer;

	@media (min-width: 1201px) {
		background: #fff !important;
	}

	@media (max-width: 1200px) {
		flex-direction: column;
		display: flex;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	&:hover {
		z-index: 2;
		box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.2);
		transform: translateZ(0);
	}

	&:active {
		transform: translateY(1px);
		transition-duration: 0.05s;
	}

	&:last-child {
		@media (max-width: 1200px) {
			margin-bottom: 0;
		}
	}

	&--disabled {
		cursor: inherit;

		&:hover {
			box-shadow: none;
		}

		&:active,
		&:active .schedulesListRow__col {
			transform: none !important;
		}
	}

	&__col {
		display: table-cell;
		padding: 20px 15px 19px;
		vertical-align: middle;
		border-bottom: solid 1px #f1f1f1;
		color: #232323;

		@media (min-width: 1201px) {
			&:first-child {
				padding-left: 30px;
			}

			&:last-child {
				padding-right: 30px;
			}
		}

		@media (max-width: 1200px) {
			display: block;
			padding: 0;
			border: 0;
		}

		&-class {
			color: $primary;
			font-family: "Akrobat", sans-serif;
			font-size: 1.8rem;
			color: #8ab0ab;
			font-weight: bold;

			@media (max-width: 1200px) {
				margin-bottom: 5px;
			}
		}

		&-location,
		&-room,
		&-teacher {
			color: #8ab0ab;

			@media (max-width: 1200px) {
				margin: 3px 0;
				color: #000;
				font-weight: bold;
			}
		}

		&-location::before,
		&-room::before,
		&-teacher::before {
			@media (max-width: 1200px) {
				display: inline-block;
				width: 20px;
				height: 16px;
				margin-right: 8px;
				content: "";
				vertical-align: middle;
			}
		}

		&-location::before {
			background: url(../../../img/icons/placemark-teal2.svg) no-repeat center /
				contain;
		}

		&-teacher::before {
			background: url(../../../img/icons/mat-teal2.svg) no-repeat center /
				contain;
		}

		&-notice {
			color: #999;
			font-size: 1.4rem;

			.text-red {
				color: #e40621;
			}

			@media (max-width: 1200px) {
				position: absolute;
				right: 20px;
				bottom: 0;
				align-items: center;
				justify-content: flex-end;
				display: flex;
				width: 33%;
				height: 48px;
				padding: 0;
				color: #fff;
				line-height: 1.1em;
				pointer-events: none;
			}
		}
	}

	&:active &__col {
		transform: translateY(1px);
		transition-duration: 0.05s;
	}

	&__time {
		line-height: normal;

		@media (max-width: 1200px) {
			width: 100%;
			color: $primary;
			font-family: "Akrobat", sans-serif;
			font-size: 2rem;
			text-align: left;
		}
	}

	&__btn {
		width: 120px;
		pointer-events: none;

		&--isLoading {
			background: #fff !important;
			border: 0;
		}

		@media (max-width: 1200px) {
			width: 96px;
			height: 36px;
		}
	}
}
