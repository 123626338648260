.h-full {
  height: 100% !important;
}

.modal {
	position: relative;
	width: calc(100% - 30px);
	width: -moz-calc(100% - 30px);
    width: -webkit-calc(100% - 30px);
	height: fit-content !important;
	overflow: visible !important;


	right: 0;
	bottom: 0;
	top: 0;
	left: 0;
	margin: 10rem auto;

	background: #f4f4f4;
	animation: fade-in 0.4s linear forwards 0.1s,
		from-bottom 0.4s ease-out forwards 0.1s;
	// opacity: 0;
	display: block !important;

	&__btn-close {
		position: fixed;
		z-index: 999 !important;
		right: 0;
		top: -30;
		width: 25px;
		height: 25px;
		bottom: calc(100% + 14px);
		bottom: -moz-calc(100% + 14px);
		bottom: -webkit-calc(100% + 14px);

		background: url(../../img/icons/close-win.svg) no-repeat;
		background-size: cover;
		border: 0;
		z-index: 999 !important;
		cursor: pointer;

		&,
		&:focus {
			outline: 0;
		}

		&:hover {
			transform: scale(1.05);
			transition-duration: 0.1s;
		}

		&:active {
			transform: scale(0.95);
			transition-duration: 0.01s;
		}
	}

	&__header {
		position: relative;
		width: 100%;
		min-height: 120px;
		padding: 40px 15px 45px;
		margin-bottom: 40px;
		background: url(../../img/ui/bg-overlay-header.svg) no-repeat center /
			cover;

		@media all and (-ms-high-contrast: none) {
			background-size: auto 150%;
		}
	}

	&__title {
		color: #fff;
		font-size: 3.2rem;
	}

	&__icon {
		position: absolute;
		top: calc(100% - 34px);
		left: calc(50% - 34px);
		width: 68px;
		height: 68px;
		border: solid 2px #fff;

		img {
			display: block;
			height: auto;
			max-width: 100%;

			@media all and (-ms-high-contrast: none) {
				max-width: 64px;
				max-height: 64px;
			}
		}
	}

	&__header--tall &__icon {
		position: static;
		margin-top: 5px;
	}
}
