.ymFriendDetail {
	background: #fff;
	padding: 25px;
	border-bottom: 1px solid #eee;

	@media (max-width: 767px) {
		padding: 20px;
		&:active {
			position: relative;
			z-index: 1;
			box-shadow: 2px -2px 15px 0px rgba(0, 0, 0, 0.2);
		}
	}
	&__share__btn {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		cursor: pointer;
		text-align: right;

		/* Primary/Teal */

		color: #59baae;
	}
	&__not__fri__yet {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		/* Grey */
		color: #8c8c8c;
	}
	&:last-child {
		border-bottom: none;
	}

	&__avatar {
		width: 50px;
		height: 50px;
		border-radius: 100%;
		vertical-align: middle;
		display: inline-block;
		font-weight: bold;
		font-size: 14px;
		padding-top: 15px;
	}

	&__btn {
		width: 159px;
	}

	// bootstrap's no-gutters > .col is higher in precendence than BEM.
	// so, used child selector to apply padding left.
	.name {
		padding-left: 20px;
	}

	&__show-remove-on-hover {
		&:hover {
			.pending-btn {
				display: none;
			}
			.remove-btn {
				display: block;
			}
		}
		.remove-btn {
			display: none;
		}
		.pending-btn {
			display: block;
		}
	}

	&__confirmation {
		min-width: 159px;
		text-align: center;
		a {
			cursor: pointer;
			margin-right: 10px;
		}
	}

	&__link {
		font-weight: bold;
		font-size: 1.6rem;
		color: #00c6d2;

		&--remove {
			color: #9b9b9b;
		}
	}

	&__highlight {
		font-size: 1.2rem;
		font-weight: bold;
		color: #f7a754;
		font-family: "Akrobat", sans-serif;
		position: absolute;
		top: 18px;
		line-height: 0;
		text-transform: uppercase;
		animation: fade-in 0.3s linear, from-top 0.2s ease-out;
	}

	&__ellipsis {
		display: inline-block;
		width: 130px;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;

		@media (max-width: 500px) {
			width: 60px;
		}
	}
}

.friend-request {
	font-weight: normal;
	font-size: 1.4rem;
	text-transform: none;
}

.request__date {
	margin-bottom: 12px;
	font-weight: normal;
}

.friendsModalList__item-name {
	// overflow: hidden;
	// text-overflow: ellipsis;
	// white-space: nowrap;
}

.friendsModalList__item-contents {
	position: relative;
	margin-bottom: 6px;
}
