@import "../../../base/styles/colors";

.errorMessage {
	line-height: 1.2em;
	animation: shake 0.3s ease-out;

	&__icon {
		width: 24px;
		height: 21px;
		margin-right: 10px;
		background: url(../../../img/icons/warning-black.svg) no-repeat center /
			contain;
	}

	&__contents {
		max-width: calc(100% - 34px);
		color: $error;
	}
}
