.classPackIcon {
  position: relative;

  &--no-bg {
    background: none !important;
    box-shadow: none !important;
  }

  &.shareable {
    background: #1f455f;

    @media (min-width: 993px) {
      box-shadow: 0 0 0 1px #1f455f;
      border: 2px solid white;
    }
  }

  &.light-blue {
    background: #8ebfc5;

    @media (min-width: 993px) {
      box-shadow: 0 0 0 1px #8ebfc5;
      border: 2px solid white;
    }
  }

  &.unlimited {
    background: url(../../../img/icons/infinity_circle.svg) no-repeat center / contain;
  }

  &.isLoading {
    background: #fff;
    box-shadow: 0 0 0 1px #ddd;
  }

  &__animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    > div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
