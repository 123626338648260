//base rules
//target only element selectors, no classes or ids!
@charset 'utf-8';
@import "./colors";

html,
body {
	width: 100%;
	height: 100%;
}

html {
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	-ms-overflow-style: scrollbar;

	&.disable-scroll {
		position: fixed;
		overflow-y: hidden;
	}

	@media (max-width: 1200px) {
		font-size: 60%;
	}

	@media (max-width: 1000px) {
		font-size: 55%;
	}

	@media (max-width: 360px) {
		font-size: 50%;
	}

	@media (max-width: 767px) {
		&.disable-scroll-mobile {
			position: fixed;
			overflow-y: hidden;
		}
	}
}

body {
	color: $base;
	font-family: "Inter", Sans-Serif;
	font-size: 1.6rem;
}

*::selection {
	color: #fff;
	background: $cta;
}

label {
	font-weight: normal;
	cursor: pointer;
}

a {
	display: inline-block;
	color: $link;
	text-decoration: none;
	transition: all 0.3s ease-out;

	&:hover,
	&:active,
	&:focus {
		color: $cta;
		text-decoration: none;
	}

	&:active {
		transform: translateY(1px);
		transition-duration: 0.05s;
	}
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	font-family: "Akrobat", Sans-Serif;
	font-weight: 400;
}

input,
textarea {
	caret-color: $cta;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

ul {
	padding-left: 0px;
}
