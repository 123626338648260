.panel {
  padding: 35px 40px;
  background: #FFF;
  animation: fade-in .3s linear, from-bottom .3s ease-out;

  @media (max-width:767px) {
    padding: 35px 20px;
  }

}
