@import "../../../base/styles/colors.scss";

.lateCancelPopup {
	padding: 4rem 0px;
	background-color: #f4f4f4;
	width: 40rem;
	font-family: $acrobat-font;
	margin: 0 auto;

	&__upperContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		gap: 1.5rem;
		padding: 0 2rem;
		margin-bottom: 30px;

		.iconWrapper {
			margin-bottom: 10px;
		}

		h3 {
			color: #272727;
			font-weight: 500;
			font-size: 2.4rem;
		}
		p {
			text-align: center;
			line-height: 2.2rem;
			font-size: 1.6rem;
			font-family: $inter-font;
			font-weight: 400;
		}
	}

	&__lowerContent {
		background-color: $light-grey-bg;
		padding: 1.9rem 2rem;
		.inner {
			&__info {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				padding: 14px 0px;

				border-bottom: 1px solid #232323;

				.left__side {
					h3 {
						color: $ligth-teal-text;
						font-size: 1.8rem;
						font-weight: 700;
						text-transform: uppercase;
					}

					.date {
						color: #272727;
						font-size: 1.4rem;
						font-weight: 400;
					}
				}

				.price {
					font-size: 2rem;
					font-weight: 700;
				}
			}

			&__total {
				padding-top: 1.6rem;
				display: flex;
				justify-content: space-between;

				h2 {
					font-weight: 700;
					font-size: 1.8rem;
				}

				.price {
					font-size: 2rem;
					font-weight: 700;
				}
			}
		}
	}

	&__bottom {
		padding: 30px 20px 0 20px;

		&__btn {
			width: 100%;
			border-radius: 24px;
			padding: 14px 0;
			background-color: #3e505b;
			color: #fff;
			font-size: 1.6rem;
			font-family: $inter-font;
			font-weight: 700;
			text-align: center;
			cursor: pointer;
			border: none;
			outline: none;
		}
	}
}

.showModal {
	display: block;
}

.hideModal {
	display: none;
}
