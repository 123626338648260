.friendsModalItemLoading {
	&__item-pic.isLoading {
		background: #fff;
	}

	&__item-name {
		width: 150px;
		margin-left: 20px;
	}

	&__item-wrapper {
		background: #fff;
		width: 100%;
		padding: 12px 20px;
	}

	&__content {
		background: #ccc !important;
	}
}
