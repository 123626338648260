@import "../../base/styles/colors.scss";

.initialPopup {
	padding: 0rem 0px;
	background-color: #f4f4f4;
	width: 100%;
	// height: 466px;
	font-family: $acrobat-font;
	margin: 0 auto;

	&__sliderWrapper {
		.slider-container {
			display: flex;
			align-items: center;
			justify-content: center;
			// height: 100%;
		}

		.slider {
			width: 100%;
			max-width: 600px;
			// height: 400px;
			// margin: 20px 0px;
			// top: -10px;
			text-align: center;
			// border-radius: 20px;
			overflow: hidden;
			position: relative;
		}

		.slides {
			display: flex;
			overflow-x: scroll;
			position: relative;
			scroll-behavior: smooth;
			scroll-snap-type: x mandatory;
		}

		.slide:nth-of-type(even) {
			// background-color: rgb(250, 246, 212);
		}

		.slide {
			display: flex;
			justify-content: center;
			align-items: center;
			// flex-shrink: 0;
			// width: 100%;
			// height: 400px;
			// scroll-snap-align: center;
			// margin-right: 0px;
			// box-sizing: border-box;
			// // background: white;
			// transform-origin: center center;
			// transform: scale(1);
		}

		.slide__text {
			font-size: 40px;
			font-weight: bold;
			font-family: sans-serif;
		}

		.slide a {
			background: none;
			border: none;
		}

		// a.slide__prev,
		// .slider::before {
		// 	transform: rotate(135deg);
		// 	-webkit-transform: rotate(135deg);
		// 	left: 5%;
		// }

		// a.slide__next,
		// .slider::after {
		// 	transform: rotate(-45deg);
		// 	-webkit-transform: rotate(-45deg);
		// 	right: 5%;
		// }

		// .slider::before,
		// .slider::after,
		// .slide__prev,
		// .slide__next {
		// 	position: absolute;
		// 	top: 48%;
		// 	width: 35px;
		// 	height: 35px;
		// 	border: solid black;
		// 	border-width: 0 4px 4px 0;
		// 	padding: 3px;
		// 	box-sizing: border-box;
		// }

		// .slider::before,
		// .slider::after {
		// 	content: "";
		// 	z-index: 1;
		// 	background: none;
		// 	pointer-events: none;
		// }

		.slider__nav {
			box-sizing: border-box;
			position: absolute;
			bottom: 5%;
			left: 50%;
			width: 200px;
			margin-left: -100px;
			text-align: center;
		}

		.slider__navlink {
			display: inline-block;
			height: 15px;
			width: 15px;
			border-radius: 50%;
			background-color: black;
			margin: 0 10px 0 10px;
		}
	}
	.bgColor {

    height: 460px !important;
		width: 460px;

		img {
			// width: 100%;
			// height: 100%;

      height: 460px !important;
			width: 460px;
		}
	}

	&__title {
		font-family: "Akrobat";
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;
		margin-top: 30px;
		/* identical to box height */
		text-align: center;
		text-transform: uppercase;
		color: #272727;
	}
	&__content {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		max-width: 400px;
		// padding: 0px 42px;
		line-height: 22px;
		/* or 138% */
		text-align: center;
		// margin-bottom: 40px;
		color: #272727;
	}
	&__upperContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		gap: 1.5rem;
		width: 40rem;
		padding: 0 0rem;
		margin: 0px auto;
		margin-bottom: 30px;

		.iconWrapper {
			margin-bottom: 10px;
		}
	}

	&__lowerContent {
		background-color: $light-grey-bg;
		padding: 1.9rem 2rem;
		.inner {
			&__info {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				padding: 14px 0px;

				border-bottom: 1px solid #232323;

				.left__side {
					h3 {
						color: $ligth-teal-text;
						font-size: 1.8rem;
						font-weight: 700;
						text-transform: uppercase;
					}

					.date {
						color: #272727;
						font-size: 1.4rem;
						font-weight: 400;
					}
				}

				.price {
					font-size: 2rem;
					font-weight: 700;
				}
			}

			&__total {
				padding-top: 1.6rem;
				display: flex;
				justify-content: space-between;

				h2 {
					font-weight: 700;
					font-size: 1.8rem;
				}

				.price {
					font-size: 2rem;
					font-weight: 700;
				}
			}
		}
	}

	&__bottom {
		padding: 30px 20px 0 20px;

		&__btn {
			width: 100%;
			border-radius: 24px;
			padding: 14px 0;
			background-color: #3e505b;
			color: #fff;
			font-size: 1.6rem;
			font-family: $inter-font;
			font-weight: 700;
			text-align: center;
			cursor: pointer;
			border: none;
			outline: none;
		}
	}
}

.showModal {
	display: block;
}

.hideModal {
	display: none;
}
