@import "../../../../base/styles/colors.scss";

/** 
 * most styles are inherited from Authentication.scss in parent 
 * to maintain consistent look & feel 
 */
.loginForm {
	margin-top: 21px;

	&__title {
		font-size: 2.8rem;
	}

	&__link {
		font-weight: bold;
	}

	&__message {
		margin: 20px 0;
	}

	&__btn {
		max-width: 172px;
	}

	&__user {
		margin-bottom: 20px;
		font-size: 1.6rem;

		&-pic {
			width: 50px;
			height: 50px;
			background: url('../../../../img/icons/person.svg') no-repeat #fafafa center /
				25px auto;
			bottom: 0;
			display: inline-flex;
			vertical-align: middle;
			background: #aabbb9;
			font-size: 2rem;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;

			right: -42px; // same width of the picture to set it on the right
		}

		&-details {
			flex-grow: 1;
			font-size: 1.6rem;
		}

		&-change {
			color: $link;
			font-weight: bold;
			background: none;
			border: 0;
			cursor: pointer;

			&:hover {
				color: $cta;
			}

			&:focus {
				outline: 0;
			}
		}
	}

	&__or {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	&__submit-wrapper {
		margin-top: 30px;
	}
}
