@import "../../../base/styles/colors";

.rateClassModal {
	&__content {
		display: grid;
		width: 900px;
		grid-template-columns: 1fr 1fr;
		@media (max-width: 992px) {
			width: auto;
			grid-template-columns: 1fr;
		}
	}
	&__left {
		padding: 30px;
		background-color: #fff;
		@media (max-width: 992px) {
			display: none;
		}
	}
	&__right {
		background-color: #f4f4f4;
		padding: 28px 40px 40px;
		@media (max-width: 992px) {
			padding: 0;
		}
	}
	&__rateThisClass {
		margin: 0;
		padding: 0;
		font-family: Akrobat, sans-serif;
		font-size: 2rem;
		font-weight: bold;
		color: #232323;
	}
	&__class {
		&__name {
			margin-top: 34px;
			font-family: "Akrobat", Sans-Serif;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.07;
			color: #8ab0ab;
			text-transform: uppercase;
		}
		&__label {
			margin: 0;
			padding: 0;
			margin-top: 20px;
			font-family: "Akrobat", Sans-Serif;
			font-size: 1.4rem;
			font-weight: bold;
			color: #8ab0ab;
		}
		&__description {
			margin: 0;
			padding: 0;
			margin-top: 4px;
			font-family: "Inter", Sans-Serif;
			color: #232323;
		}
	}
	&__mobile-show {
		display: none;
		@media (max-width: 992px) {
			display: block;
		}
	}
	&__desktop-show {
		display: block;
		@media (max-width: 992px) {
			display: none;
		}
	}
	&__rate {
		&__today {
			&__howYouFeeling {
				margin: 0;
				padding: 0;
				font-family: "Inter", Sans-Serif;
				font-weight: bold;
				line-height: 1.38;
				color: #232323;
				@media (max-width: 992px) {
					margin-top: 20px;
					font-weight: normal;
					text-align: center;
				}
			}
			&__button {
				width: 68px;
				height: 92px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				@media (max-width: 992px) {
					width: 60px;
				}
				&__icon {
					&__container {
						background-color: #fff;
						border-radius: 50%;
						width: 100%;
						height: 68px;
						display: flex;
						align-items: center;
						justify-content: center;
						&__active {
							background-color: #59baae;
						}
						@media (max-width: 992px) {
							height: 60px;
						}
					}
					&__label {
						margin: 0;
						padding: 0;
						font-family: "Inter", Sans-Serif;
						font-size: 1.2rem;
						line-height: 1.5;
						text-align: center;
						color: #59baae;
						&:hover {
							font-weight: bold;
						}
					}
				}
				&__container {
					margin-top: 20px;
					display: grid;
					grid-template-columns: repeat(5, minmax(0, 1fr));
					gap: 10px;
					align-items: center;
					justify-content: space-between;
					@media (max-width: 992px) {
						padding: 0 18px;
					}
				}
			}
		}
		&__teacher {
			&__name {
				margin: 0;
				padding: 0;
				margin-top: 40px;
				font-family: "Inter", Sans-Serif;
				font-weight: bold;
				line-height: 1.38;
				color: #232323;
				@media (max-width: 992px) {
					font-weight: normal;
					margin-top: 16px;
					text-align: center;
				}
			}
			&__container {
				margin-top: 20px;
				display: grid;
				grid-template-columns: 80px auto;
				gap: 20px;
			}
			&__profile {
				width: 80px;
				height: 80px;
				object-fit: cover;
				border-radius: 50%;
				&__container {
					width: 80px;
					height: 80px;
					border-radius: 50%;
					background-color: #adbab9;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					text-transform: uppercase;
					font-weight: bold;
					font-size: 2rem;
					@media (max-width: 992px) {
						margin-top: -40px;
					}
				}
				&__mobile-container {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			&__star {
				width: 28px;
				height: 28px;
				object-fit: cover;
				margin-right: 12px;
				margin-bottom: 8px;
				&__container {
					display: flex;
					align-items: center;
					@media (max-width: 992px) {
						margin: 0 auto;
						margin-top: 16px;
						width: 200px;
					}
				}
			}
			&__mobile {
				margin-top: 70px;
				width: 100%;
				background: #fff;
			}
		}
		&__textArea {
			width: 100%;
			height: 100%;
			border: none;
			overflow: auto;
			outline: none;
			resize: none;
			font-family: "Inter", Sans-Serif;
			font-size: 1.6rem;
			line-height: 1.13;
			color: #232323;
			@media (max-width: 992px) {
				background-color: #eee;
			}
			&__label {
				font-family: "Inter", Sans-Serif;
				font-size: 1rem;
				color: #9baca2;
			}
			&__container {
				margin-top: 20px;
				border-radius: 4px;
				padding: 10px 15px;
				width: 380px;
				height: 120px;
				background-color: #fff;
				display: flex;
				flex-direction: column;
				@media (max-width: 992px) {
					width: 90%;
					margin: 25px 18px 0;
					background-color: #eee;
				}
			}
			&__count {
				width: 100%;
				text-align: right;
				font-family: "Inter", Sans-Serif;
				font-size: 1.2rem;
				color: #aabbb9;
			}
		}
		&__done {
			margin-top: 30px;
			margin-left: auto;
			width: 154px;
			@media (max-width: 992px) {
				width: 90%;
				margin: 35px auto 20px;
			}
		}
	}
}
