@import "../../base/styles/colors";
@import "../../base/styles/mixins/media-queries.scss";

.form {
	margin: 0;

	&--check-disable {
		&:invalid .form__button {
			color: #fff;
			pointer-events: none;
			color: #e5e5e5 !important;
			background: #ccc !important;
			border-color: #ccc;
		}
	}

	//overall
	&.sending {
		opacity: 0.7;
		pointer-events: none;
	}

	&--busy {
		cursor: wait !important;
		opacity: 0.5;

		* {
			pointer-events: none;
		}
	}

	&__msg {
		&--error {
			display: none;
		}
	}

	&__field {
		&--error {
			display: block;
			color: $error;
			font-size: 1.4rem;
			animation: shake 0.3s ease-out;
		}
	}

	&__group {
		margin-bottom: 20px;
		border-radius: 4px;
		transition: all ease-out 0.15s;
		.form__label {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}

		.form__field {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}

		.form__label,
		&__field {
			background: #fff !important;

			&:disabled,
			&-disabled {
				pointer-events: none;
				cursor: not-allowed;
				background: #e8e8e8 !important;
			}
		}

		&:active:not(:disabled) {
			transform: translateY(1px);
			transition-duration: 0.05s;
		}

		&--semi-dark {
			input,
			& {
				background: #e9e9e9 !important;
			}
			input:-webkit-autofill {
				background: #e9e9e9 !important;
				box-shadow: 200px 200px 100px #e9e9e9 inset !important;
				-webkit-box-shadow: 200px 200px 100px #e9e9e9 inset !important;
			}
		}

		.form__field {
			padding-top: 0px;
		}
	}

	//label
	&__label {
		color: $label-light;
		font-size: 1.2rem;
		padding: 15px 0 0 15px;
	}

	//text input / textarea / select
	&__field,
	&__textarea,
	&__select {
		width: 100%;
		padding: 14px;
		-webkit-appearance: none;
		background: #fff;
		border: 0;
		border-radius: 0;
		outline: 0;
		transition: all ease-out 0.15s;

		&:-webkit-autofill {
			background: white !important;
			box-shadow: 200px 200px 100px white inset;
			-webkit-box-shadow: 200px 200px 100px white inset;
		}

		&:hover:not(:disabled) {
			border-color: rgba(84, 191, 205, 0.5);
		}

		&:focus {
			border-color: #54bfcd;
		}

		&:disabled {
			background: #e8e8e8 !important;
			pointer-events: none;
			box-shadow: 200px 200px 100px #e8e8e8 inset;
			-webkit-box-shadow: 200px 200px 100px #e8e8e8 inset;
			opacity: 1 !important;
		}

		&::-webkit-input-placeholder {
			color: $placeholder;
		}

		&:-ms-input-placeholder {
			color: $placeholder;
		}

		&::placeholder {
			color: $placeholder;
		}

		@media (max-width: 767px) {
			background: #fff;
			border-color: #fff;
		}
	}

	&__select {
		// For Firefox not suppor to transform
		&:active:not(:disabled) {
			transform: none;
			transition-duration: 0.05s;
		}
	}

	//text input error / textarea error
	&--do-validate &__group,
	&--do-validate &__field,
	&--do-validate &__textarea,
	&--do-validate &__select {
		&:invalid,
		&--error {
			color: $error;
			border-color: $error;
			animation: shake 0.3s ease-out;
		}

		&:invalid::-webkit-input-placeholder {
			color: $placeholder-error;
		}

		&--error::-webkit-input-placeholder {
			color: $placeholder-error;
		}

		&:invalid::-ms-input-placeholder,
		&--error::-ms-input-placeholder {
			color: $placeholder-error;
		}

		&:invalid ~ .form__msg--error,
		&--error ~ .form__msg--error {
			display: block;
			color: $error;
			font-size: 1.4rem;
			animation: shake 0.3s ease-out;
		}

		&--suppress-error ~ .form__msg--error {
			display: none;
		}
	}

	//select
	&__select {
		padding-right: 50px;
		@include screen-max(650) {
			padding-right: 30px;
		}
		appearance: none;
		background: url(../../img/icons/chevron-down-black.svg) no-repeat #f9f9f9
			calc(100% - 15px) 50% / auto 5px;
	}

	&--do-validate &__select {
		&:invalid {
			background-image: url(../../img/icons/chevron-down-red.svg);
		}
	}
}
