@import "../../../base/styles/mixins/media-queries.scss";
@import "../../../base/styles/colors";

.headerNavMain {
	//100% - btn width
	width: 100%;
	max-width: 900px;
	margin-top: 8px;
	text-align: center;
	transition: all 0.3s ease;
	position: relative;
	top: -8px;
	list-style: none;

	@media (max-width: 1200px) and (min-width: 993px) {
		top: 5px;
		max-width: 100%;
	}

	@media (min-width: 1201px) {
		margin-left: 20px;
	}

	@media (max-width: 1100px) {
		margin-left: auto;
		top: 5px;
	}

	@media (max-width: 992px) {
		display: block;
		width: 100%;
		text-align: left;
		margin-left: 0;
	}

	&--condensed {
		max-width: 800px;
	}

	&__list {
		position: static;
		display: flex;
		list-style: none;
	
		margin: 0;
		font-family: "Akrobat", Sans-Serif;

		@media (min-width: 1250px) {
			justify-content: space-evenly;
		}

		@media screen and (-ms-high-contrast: active),
			screen and (-ms-high-contrast: none) {
			// justify-content: space-between;
			// padding: 0 24px;
		}

		@media (max-width: 1200px) and (min-width: 993px) {
			justify-content: flex-end;
		}

		@media (max-width: 992px) {
			display: block;
			overflow: auto;
		}
	}

	&--condensed .headerNavItem__link {
		@media (min-width: 993px) {
			font-size: 1.6rem;
		}
	}
}

.headerNavMain__super {
	position: relative;
	cursor: pointer;

	&:hover {
		&:before {
			content: "-";
		}
	}
	&:before {
		position: absolute;
		content: "+";
		color: #59baae;
		font-size: 2.3rem;
		width: 25px;
		right: 0;
		top: -7px;
		font-family: "Inter", sans-serif;
		font-weight: 300;

		@include screen-max(992) {
			display: none;
		}
	}
}

.headerNavMain__sub {
	position: absolute;
	left: -15px;
	top: 100%;
	margin-top: -1px;
	display: none;
	width: 145px;
	padding: 12px 12px 12px 4px;
	text-align: left;
	list-style: none;
	background: #fff;
	line-height: 1.14;
	font-weight: normal;
	
	&:before {
		content: "";
		right: 0;
		left: 0;
		bottom: 0;
		height: 3px;
		background: #232323;
		position: absolute;
		@media (max-width: 768px) {
			display: none;
		}
	}

	@media (min-width: 993px) {
		.HeaderNavItem:hover & {
			display: block;
			animation: fade-in 0.3s linear, from-top 0.2s ease-out;
		}
	}

	@media (max-width: 992px) {
		position: static;
		display: none;
		width: 100%;
		padding: 0;
		margin: -5px 0 10px;
		background: #fff;
		box-shadow: none;

		.HeaderNavItem--active & {
			display: block;
		}
	}

	&-link {
		display: block;
		padding: 8px 10px;
		color: #363737;
		font-size: 1.4rem;
		line-height: 1.14;
		line-height: 1em;

		@media (max-width: 992px) {
			padding: 8px 10px 8px 15px;
			font-size: 1.8rem;
		}

		&:hover {
			color: $cta;
		}

		&--active,
		&--active:focus {
			color: $cta;
		}
	}
}
