.deleteConfirmPopup {
	top: -100%;
	left: 50%;
	position: fixed;
	width: 50rem;
	height: 30rem;
	background-color: white;
	z-index: 10;
	animation: 1.5s ease-in-out forwards showPopup;
	transform: translate(-50%, -50%);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

	&[data-close="true"] {
		animation: 1s ease-out alternate forwards closePopup;
	}

	@keyframes showPopup {
		from {
			top: -100%;
			left: 50%;
		}
		to {
			top: 50%;
			left: 50%;
		}
	}

	@keyframes closePopup {
		to {
			top: -100%;
			left: 50%;
		}
		from {
			top: 50%;
			left: 50%;
		}
	}

	&__button {
		position: absolute;
		top: -4rem;
		right: 0;
	}

	&__content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img {
			margin-bottom: 1.5rem;
		}
		div {
			font-family: "Akrobat";
			font-weight: 700;
			font-size: 2rem;
			line-height: 2.498rem;
			text-align: center;
		}
	}
}
