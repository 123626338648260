@import "../../../base/styles/colors";

.checkbox {
	&__icon {
		position: relative;
		width: 15px;
		height: 15px;
		margin-right: 8px;
		background: #aabbb9;
		cursor: pointer;

		&::after {
			display: block;
			width: 5px;
			height: 5px;
			content: "";
			background: #fff;
			border-radius: 100%;
			transform: scale(0);
			transition: transform 0.2s ease-out;
		}
	}

	&__wrapper {
		max-width: 50px;
	}

	&__text {
		color: $type-face-color;
		font-size: 1.6rem;
	}

	&--default {
		.checkbox__icon::before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			content: "";
			background: $cta;
			border-radius: 100%;
			transform: scale(0);
		}

		.checkbox__icon::after {
			width: 5px;
			height: 5px;
		}
	}

	&--tick {
		.checkbox__icon::after {
			width: 100%;
			height: 100%;
			background: url(../../../img/icons/tick.svg) no-repeat $cta center / 100%
				auto;
		}
	}

	&--square {
		.checkbox__icon,
		.checkbox__icon::before,
		.checkbox__icon::after {
			border-radius: 3px;
		}

		.checkbox__icon {
			overflow: hidden;
		}
	}

	&:hover {
		.checkbox__icon {
			border-color: rgba(84, 191, 205, 0.5);
			transform: scale(1.1);
			transition-duration: 0.1s;
		}
	}

	&:active {
		.checkbox__icon {
			border-color: $cta;
			transform: scale(0.95);
			transition-duration: 0.02s;
		}

		.checkbox__text {
			color: $base;
		}
	}

	&--selected {
		.checkbox__icon {
			border-color: $cta;
		}

		.checkbox__text {
			color: $base;
		}

		.checkbox__icon::before {
			transform: scale(1);
		}

		.checkbox__icon::after {
			transform: scale(1);
		}
	}

	&--disabled {
		color: #999;
		pointer-events: none;

		.checkbox__icon {
			background-color: #ccc;
			border-color: #ccc;
		}
	}

	.form--do-validate &--form-error,
	&--error {
		color: $error;
		animation: shake 0.3s ease-out;

		.checkbox__icon {
			border-color: $error;
		}
	}
}
