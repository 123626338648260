.forgotPasswordModal {
	&__contents.auth__contents {
		padding-bottom: 10px;
		max-width: 459px;
	}

	&__error {
		margin: 20px 0;
	}

	&__btn {
		max-width: 172px;
		margin: 14px 0 40px auto;
	}

	&__link {
		margin-bottom: 0;
	}

	&__form {
		margin-top: 30px;
	}
}
