@import "../../base/styles/colors.scss";

.footer {
	position: relative;
	padding: 0 30px;
	text-align: left;
	background: #fff;

	@media (max-width: 1440px) {
		padding: 0 20px;
	}

	@media (max-width: 992px) {
		padding: 0 15px;
	}

	&__inner {
		position: relative;
		padding: 40px 0;

		width: calc(100% - 60px);
		max-width: 1280px;
		margin: 0 auto;
	}

	&__col {
		@media (max-width: 992px) {
			margin-bottom: 25px;
		}
	}

	&__nav-title {
		margin-bottom: 1.4rem;
		color: #aabbb9;
		font-size: 1.8rem;
		font-weight: bold;

		@media (max-width: 992px) {
			margin-bottom: 10px;
		}
	}

	&__link-wrap {
		margin-bottom: 8px;
		display: flex;
	}

	&__link {
		align-items: center;
		display: flex;
		color: #3e505b;
		font-size: 1.4rem;

		:hover {
			color: $base;
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	&__link:hover &__icon {
		filter: brightness(0);
	}

	&__divider {
		width: calc(100% + 60px);
		height: 1px;
		margin: 50px -30px 30px;
		background: #e6e6e6;

		@media (max-width: 1440px) {
			width: calc(100% + 40px);
			margin: 50px -20px 30px;
		}

		@media (max-width: 992px) {
			width: calc(100% + 30px);
			margin: 10px -15px 30px;
		}
	}
}
