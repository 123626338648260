//modular and reusable rules
//meant to be used on multiple elements throughout the app
@charset 'utf-8';
@import "./colors";
@import "./mixins/utilities.scss";

.animated {
	&,
	&::before,
	&::after {
		backface-visibility: hidden;
		transition: all 0.2s ease-out;
	}
}

.no {
	&-select {
		user-select: none;
		webkit-touch-callout: none;
	}

	&-drag {
		user-drag: none;
	}

	&-interact {
		pointer-events: none;
	}
}

.circle {
	z-index: 1;
	// z-index used to fix Chrome rounded corner transition bug
	border-radius: 100%;
}

.inline-block {
	display: inline-block;
	vertical-align: middle;
}

.overlay-backing {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
}

.abs-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.click-efx {
	cursor: pointer;
}

.click-efx:active {
	transform: translateY(1px);
	transition-duration: 0.05s;
}

a.no-click-efx:active {
	transform: none;
}

.btn {
	cursor: pointer;

	:active .btn-inner {
		transform: translateY(1px);
		transition-duration: 0.1s;
	}
}

.error {
	color: $error;
	animation: shake ease-out 0.3s;
}

/* ==========================================================================
   typography
   ========================================================================== */
.text {
	&-thin {
		font-weight: 100;
	}

	&-extra-light {
		font-weight: 200;
	}

	&-light {
		font-weight: 300;
	}

	&-semi-bold {
		font-weight: 600;
	}

	&-bold {
		font-weight: 700;
	}

	&-extra-bold {
		font-weight: 800;
	}

	&-strikethrough {
		text-decoration: line-through;
	}

	&-reminder {
		color: $placeholder;
		font-size: 1.4rem;
	}
}

/* ==========================================================================
   loading state
   ========================================================================== */
.isLoading {
	position: relative;
	overflow: hidden;
	pointer-events: none;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		display: block;
		width: 100%;
		height: 100%;
		content: "";
		background: #ddd;
	}

	&[class^="col-"]::before,
	&.row::before {
		left: 15px;
		width: calc(100% - 30px);
	}

	&--animated::before {
		background: linear-gradient(
			-60deg,
			rgba(221, 221, 221, 1) 45%,
			rgba(221, 221, 221, 0.5) 50%,
			rgba(221, 221, 221, 1) 55%
		);
		background-size: 200%;
		animation: isLoading 2s linear infinite;
	}

	* {
		visibility: hidden !important;
	}
}

@keyframes isLoading {
	0%,
	20% {
		background-position: 150% 0%;
	}

	80%,
	100% {
		background-position: -50% 0%;
	}
}

.col--no-gutter {
	padding-right: 0px;
	padding-left: 0px;
}

.circle-badge {
	align-items: center;
	justify-content: center;
	display: flex;
	width: 56px;
	height: 56px;
	color: white;
	font-family: "Akrobat", Sans-Serif;
	font-size: 2.2rem;
	line-height: 0;
	background: #54939a;
	border-radius: 100%;

	@media (min-width: 993px) {
		box-shadow: 0 0 0 1px #54939a;
		border: 2px solid white;
	}

	@media (max-width: 400px) {
		width: 45px;
		height: 45px;
	}

	span {
		display: inline-block;
		margin-top: 3px;
	}
}

.yoga-button {
	align-items: center;
	justify-content: center;
	display: flex;
	color: #fff;
	font-size: 1.4rem;
	font-weight: bold;
	background: $cta;
	border: solid 2px;
	border-color: $cta;
	cursor: pointer;

	&:hover,
	&:active {
		color: $cta;
		background: #fff;
		border-color: $cta;
	}

	&.button--disabled {
		color: #e5e5e5;
		background: #ccc;
		border-color: #ccc;
	}

	&:focus,
	&:active {
		outline: 0;
	}

	&--disabled {
		pointer-events: none;
	}

	&:active &-inner {
		transform: translateY(1px);
		transition-duration: 0.05s;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.img-fluid {
	width: 100%;
	height: auto;
}

.pl-sm-0 {
	@media (max-width: 992px) {
		padding-left: 0 !important;
	}
}

.mt-20 {
	margin-top: 20px;
}

.word-wrap {
	@include word-wrap();
}
