.registerForm {
	&__form {
		margin-top: 28px;
	}

	&__title.auth__title {
		margin-top: 21px;
		margin-bottom: 5px;
	}

	&__error {
		margin: 20px 0;
	}

	.auth__or {
		margin-top: 28px;
	}

	&__instructions {
		margin-bottom: 30px;
		color: #999;
		font-size: 1.4rem;
	}

	&__btn {
		max-width: 172px;
		margin-left: auto;
		margin-top: 30px;
	}

	form {
		margin-bottom: 0;
	}
}
