@import "../../../base/styles/colors.scss";

.checkEmailModal {
	&__contents {
		padding-bottom: 40px;
	}

	&__heading {
		color: $light-blue-grey;
		font-size: 2.8rem;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	&__message {
		font-weight: bold;
		margin-bottom: 8px;
	}

	&__resend {
		display: inline;
		padding: 0;
		color: $link;
		background: none;
		border: 0;

		&:hover {
			color: $cta;
		}

		&:active,
		&:focus {
			outline: 0;
		}
	}

	&__btn {
		max-width: 120px;
		margin-top: 40px;
	}
}
