//app rules
//only the top level rules here
@charset 'utf-8';

@import "./colors.scss";

//Top level container
.app {
	background: $theme-bg;

	&__main {
		position: relative;
		width: calc(100% - 60px);
		max-width: 1280px;
		margin: 0 auto 200px;
		top: 15px;
		min-height: calc(100vh - 150px);
		padding-bottom: 80px;
		transition: top 0.3s ease-out, margin-bottom 0.3s ease-out,
			padding-bottom 0.3s ease-out;

		&--fullWidth {
			max-width: none;
			width: 100%;
		}

		&--height-auto {
			margin-bottom: 0;

			&,
			.transition__holder {
				min-height: auto;
			}
		}

		&-inner {
			position: relative;

			@media (max-width: 767px) {
				margin: 0 -5px;
			}
		}

		@media (max-width: 1440px) {
			width: calc(100% - 40px);

			&--fullWidth {
				width: 100%;
			}
		}

		&--condensed:not(&--fullWidth) {
			margin-bottom: 140px;
		}

		@media (max-width: 992px) {
			margin-bottom: 108px;

			&--fullWidth {
				top: 79px;
				margin-bottom: 79px;
			}
		}

		@media print {
			top: 0;
			height: auto;
			min-height: 0;
			margin: 0;
		}
	}

	&--no-header &__main {
		top: 0;
		padding-bottom: 0;
		margin-bottom: 0;

		@media (max-width: 992px) {
			padding-top: 40px;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}

	&--no-header &__main--fullWidth {
		top: 0;
		padding-top: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

//controls preloader vertical position
.transition__holder {
	min-height: 100%;
}

//Route transitions
.transition__crossfade {
	&-enter,
	&-exit {
		transition: opacity 0.2s ease-out;
	}

	&-enter {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		opacity: 0;
	}

	&-enter-active {
		z-index: 2;
		opacity: 1;
	}

	&-exit {
		opacity: 1;
	}

	&-exit-active {
		z-index: 1;
		opacity: 0;
	}
}

.cover {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	width: 100%;
	height: 100%;
}

.background-image-content {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	&--spread {
		background-size: 100% 100%;
	}

	&--fixed {
		background-attachment: fixed;
	}
}

.Collapsible {
	font-size: 1.6rem;
	color: #232323;
	padding: 3rem;
	background: #fff;

	&__trigger {
		position: relative;
		z-index: 0;
		display: block;
		width: 100%;
		transition: all 0.1s;
		cursor: pointer;
		padding-right: 25px;

		&:before {
			content: " ";
			display: block;
			position: absolute;
			top: 50%;
			right: 0;
			width: 15px;
			height: 15px;
			transform: translateY(-50%);

			background-image: url(../../img/icons/chevron-up-teal.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;

			transition: all 0.5s;
		}

		&.is-open {
			font-weight: bold;
		}
	}

	.is-closed {
		&:before {
			transform: translateY(-50%) rotate(180deg);
		}
	}

	&__contentInner {
		padding-top: 2rem;
	}
}

.sharePackModal__content,
.addPackMemberModal__content,
.teacherModal__content,
.rateClassModal__content,
.auth__contents {
	max-height: calc(100% - 120px);
	overflow: auto;
	scroll-behavior: smooth;
}
