@import "../../base/styles/mixins/utilities.scss";
@import "../../base/styles/colors";

.auth {
	&__content {
		max-height: calc(100% - (60px * 2));
		overflow: auto;
		@include styled-scrollbar();
	}

	&__contents {
		padding-top: 30px;
		padding-bottom: 30px;
		margin: 0 29px;

		.form__group {
			input {
				margin-bottom: 5px;
			}
			margin-bottom: 6px;
		}

		@media (max-width: 992px) {
			padding-bottom: 20px;
			margin: 0 25px;
		}
	}

	&-header {
		&__icon {
			width: 80px;
			height: 80px;
		}

		&__newbie-icon {
			width: 36px;
			height: 36px;
		}

		&__forward-icon {
			width: 11px;
			height: 10px;
			align-self: flex-end;
			margin-bottom: 2px;
		}

		&__text {
			color: #59baae;
			font-size: 1.6rem;
			display: inline-block;
			padding-top: 12px;
			line-height: 1;
			margin-right: 4px;
			font-weight: bold;
			font-family: "Akrobat", Sans-serif;
			text-align: right;

			&-sign-in {
				width: 53px;
			}
		}
	}

	&__title {
		font-size: 2.8rem;
		color: #232323;
		font-weight: normal;
		line-height: 2.14;
	}

	&__link {
		color: $link;
		cursor: pointer;
		font-weight: bold;

		&:hover {
			color: $base;
		}
	}

	&__password {
		&-wrapper {
			position: relative;
		}

		&-toggle {
			@extend .auth__link;
			position: absolute;
			right: 0;
			top: 2px;
			height: 100%;
			padding-right: 16px;
			background: none;
			height: auto;
			border: 0;
			font-size: 1.2rem;
			font-weight: bold;
		}

		&-toggle:focus {
			outline: 0;
		}
	}

	&__btn--social {
		margin-top: 12px;
		background: #fff;
		border-color: #fff;
		color: #000;
		position: relative;
		font-size: 1.6rem;

		.button-inner {
			font-weight: normal;
		}

		@media (max-width: 992px) {
			margin: 20px 0;
		}

		&-fb {
			color: #1877f2;
			&:hover {
				border-color: #1877f2;
			}
		}
		&-gg {
			color: #232323;
			&:hover {
				border-color: #232323;
			}
		}

		// apple login
		#appleid-signin {
			position: absolute;
			top: -15px;
			left: 0;
			bottom: 0;
			width: 100%;
			z-index: 2;
			opacity: 0;
			img {
				width: 100%;
				height: 48px;
			}
		}
	}
	&__btn--gg {
		margin: 20px 0;
		background: #3b5998;
		border-color: #3b5998;

		@media (max-width: 992px) {
			margin: 20px 0;
		}

		&:active,
		&:focus {
			color: #fff;
		}

		&:hover,
		&:active,
		&:hover:focus {
			color: #3b5998;
			border-color: #3b5998;
		}
	}

	&__btn--fb {
		margin: 20px 0;
		background: #3b5998;
		border-color: #3b5998;

		@media (max-width: 992px) {
			margin: 20px 0;
		}

		&:active,
		&:focus {
			color: #fff;
		}

		&:hover,
		&:active,
		&:hover:focus {
			color: #3b5998;
			border-color: #3b5998;
		}
	}

	&__btn-icon--fb {
		width: 28px;
		fill: #fff;
		position: absolute;
		left: 5px;
		top: -3px;
	}
	&__btn-icon--gg {
		width: 28px;
		fill: #fff;
		position: absolute;
		left: 5px;
		top: -3px;
	}

	&__btn--fb:hover &__btn-icon--fb,
	&__btn--fb:active &__btn-icon--fb,
	&__btn--fb:active &__btn-icon--gg,
	&__btn--fb:hover:focus &__btn-icon--fb {
		fill: #3b5998;
	}

	&__or {
		align-items: center;
		justify-content: space-between;
		font-family: "Akrobat", sans-serif;
		display: flex;
		color: #000;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: 1em;

		&::after {
			display: block;
			width: calc(100% - 30px);
			height: 1px;
			content: "";
			background: #000;
		}
	}
}

.input {
	&__close {
		position: absolute;
		right: 20px;
		top: 5px;
	}
}
