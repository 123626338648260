.schedulesListHeader {
	display: table-row;
	font-family: "Akrobat", sans-serif;
	font-size: 1.4rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #8ab0ab;

	@media (max-width: 1200px) {
		display: none;
	}

	&__col {
		display: table-cell;
		padding: 6px 15px 5px;
		vertical-align: middle;
		border-bottom: solid 1px #f1f1f1;

		&:first-child {
			padding-left: 30px;
		}

		&:last-child {
			padding-right: 30px;
		}
	}
}
