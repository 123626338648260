@import "../../base/styles/colors";

.btnWrapper {
	width: 100%;
	display: flex;
}
.__headerBtnPill {
	display: flex;
	width: 70%;
}
.header {
	position: sticky;
	top: 0;
	z-index: 80;
	width: 100%;
	transition: padding-top 0.3s ease, padding-bottom 0.3s ease;
	will-change: padding-top, padding-bottom;

	&__navs {
		transition: all 0.3s ease;
	}

	&__inner {
		padding: 25px 0;
		width: calc(100% - 30px);
		max-width: 1280px;
		margin: 0 auto;
		@media (max-width: 1440px) {
			padding: 25px 0;
		}
		@media (max-width: 992px) {
			padding: 15px 0;
			width: 100%;
		}
	}

	@media (max-width: 992px) {
		width: 100%;
		background: #fff;
	}

	&--condensed {
		background: #fff;
		border-bottom: solid 1px #eee;

		@media (min-width: 993px) {
			// padding-top: 12px;
			// padding-bottom: 12px;
		}
	}

	&__logo {
		width: 85px;
		height: 85px;
		backface-visibility: hidden;

		@media (max-width: 992px) {
			width: 48px;
			height: 48px;
		}
	}

	&__logo-link {
		@media (max-width: 992px) {
			margin-left: 58px;
		}
	}

	&__btn-pill {
		padding-left: 15px;
		&-edge-cases {
			display: none;
			@media (max-width: 1200px) and (min-width: 993px) {
				display: flex;
			}
		}
	}

	&__navs {
		width: calc(100% - 100px);

		@media (max-width: 992px) {
			position: absolute;
			top: 77px;
			left: 0;
			display: none;
			width: 100%;
			height: calc(100vh - 77px);
			padding: 0;
			background: #fff;

			&-group {
				height: 100%;
				padding: 0 20px 120px 20px;
				overflow: auto;
			}

			&--active {
				display: block;
				animation: fade-in 0.3s linear, from-top 0.3s ease-out;
			}

			&--must-read {
				@media (max-width: 992px) {
					padding-bottom: 70px;
				}
			}
		}
	}

	&--condensed &__navs {
	}

	&__book-nav {
		width: auto;
		height: 48px;
		display: flex;
		flex-direction: row;
		margin-top: 10px;
		@media (min-width: 500px) {
			display: none;
		}
	}

	&__book {
		position: static;
		top: calc(50% - 16px);
		right: 10px;
		width: auto;
		height: 48px;
		font-size: 1.4rem;
		text-transform: uppercase;
		display: flex;

		@media (max-width: 499px) {
			// display: none;
			width: 100%;
			font-size: 1.2rem;
		}

		@media (max-width: 1200px) and (min-width: 993px) {
			display: none;
		}

		@media (max-width: 720px) {
			position: absolute;
			top: calc(50% - 24px);
			left: auto;
			width: 100%;
			height: 40px;

			padding-right: 0;
		}


		@media (max-width: 992px) {
			position: absolute;
			top: calc(50% - 24px);
			left: auto;
			width: 270px;
			height: 40px;
			white-space: nowrap;

			padding-right: 0;
		}
	}

	&--condensed &__book {
		@media (min-width: 993px) {
			// top: calc(50% - 8px);
			// height: 42px;
		}
	}
}
