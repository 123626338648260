@import "../../base/styles/colors";
.mustReadHeader {
	background-color: $light-dark;
	padding: 15px 0;
	text-align: center;
	color: #fff;

	p {
		margin-bottom: 0;
	}
}
