@import "../../../base/styles/colors";

.headerNavUser {
	position: absolute;
	top: 23px;
	bottom: 102px;
	right: 0;
	z-index: 1;
	margin-bottom: 0;
	text-align: center;
	transition: all 0.3s ease;
	display: flex;
	align-items: center;

	@media (max-width: 992px) {
		position: static;
		margin-bottom: 20px;
		font-size: 2.4rem;
		text-align: left;
	}

	&--unauthenticated {
		transform: translateY(9px);
		padding-right: 0;

		.headerNavUser__item a {
			color: $link !important;
			font-weight: bold !important;
		}

		&.headerNavUser--condensed {
			// transform: translateY(4px);
		}
	}

	&--condensed {
		// transform: translateY(-2px);
	}

	&__down {
		width: 12px;
		height: auto;
		@media (min-width: 993px) {
			margin-left: 9px;
			margin-right: 7px;
		}
		&-right {
			@media (max-width: 992px) {
				margin-left: 10px;
			}
		}
	}

	&__list {
		padding: 0;
		margin: 0;
		list-style: none;
		position: relative;
		a {
			color: $base !important;
			font-weight: normal !important;
		}

		@media (max-width: 767px) {
			padding: 10px 5px 10px 20px;
			margin: 0 -5px 0 -20px;
			border-bottom: solid 1px #eee;
			width: 100%;
		}
	}

	&__notification {
		@media (min-width: 993px) {
			position: absolute;
			right: 0;
			padding-right: 0px !important;
		}
	}

	&__item {
		font-family: "Akrobat", Sans-Serif;
		font-size: 1.8rem;
		a {
			color: $link;
			font-weight: bold;
		}

		@media (min-width: 993px) {
			padding: 0 7px;
			line-height: 1em;
			margin-right: 7 !important;
		}

		@media (max-width: 992px) {
			display: block;
			font-size: 2.4rem;
		}

		&--w-divider {
			padding-right: 10px !important;
			&:not(:last-child)::after {
				position: absolute;
				top: calc(50% - 5px);
				right: -5px;
				display: block;
				width: 1px;
				height: 10px;
				content: "";
				background: #000;

				@media (max-width: 992px) {
					content: none;
				}
			}
		}

		&:last-child {
			padding-right: 0;
		}

		.HeaderNavItem__link::after {
			content: none;
		}
	}

	&__badge {
		@media (max-width: 767px) {
			width: 6px;
			height: 6px;
			margin: -3px 0 0 2px;
			background: #e40621;
		}
	}

	&__item--user {
		padding: 0;
		margin-left: 12px;
		margin-right: 15px !important;
		cursor: pointer;

		@media (max-width: 992px) {
			margin: 0;
		}
	}

	&__item--user .HeaderNavItem__link::after {
		@media (min-width: 993px) {
			top: calc(100% + 1px);
			right: 0;
			width: calc(100% - 20px);
			content: "";
			background: none;
		}
	}

	&--condensed &__item--user .HeaderNavItem__link::after {
		@media (min-width: 993px) {
			// width: calc(100% - 28px);
		}
	}

	&__profile-name {
		font-size: 1.8rem;
		font-weight: 600;
		color: #323232;
	}

	&__pic {
		width: 32px;
		height: 32px;
		margin-left: 6px;
		display: inline-flex;
		vertical-align: middle;
		background: #aabbb9;
		font-size: 1.4rem;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		font-family: "Inter", sans-serif;
		margin-right: 12px;

		transition: all 0.3s ease;

		@media (max-width: 992px) {
			margin-right: 8px;

			position: absolute;
			top: 0;
		}
	}

	&--condensed &__pic {
		// width: 20px;
		// height: 20px;
		// margin-right: 8px;
	}

	&__sub {
		top: calc(100% + 5px);
		right: 0;
		z-index: 5;
		min-width: 270px;

		@media (max-width: 992px) {
			margin-top: 3px;
		}

		&-link--active {
			color: $cta;
		}
	}
}
