@import "../../../../base/styles/colors";
@import "../../../../base/styles/mixins/media-queries.scss";

.checkbox__text {
    font-size: 14px !important;
 }

 .align-items-start {
  align-items: start !important;
 }
.checkbox__wrapper {
  margin-top: 0.5rem !important;
}
/** most styles are inherited from Authentication.scss in parent to maintain consistent look & feel */
.termAndConditionModal {

	border-radius: 0px;


	.location__contents {
		width: 415px;
    padding: 0rem 5rem;
		height: 100%;
		&__container {
			height: 66px;

			margin: 0px auto;
			text-align: center;
			margin-bottom: 1.375rem;
		}
		&__icon {
			margin-top: 40px;
			margin-bottom: 16px;
			text-align: center;
		}
		&__btn {
			width: 335px;
			height: 48px;
			left: 553px;
			margin: 0px auto;
			top: 572px;
			display: flex;
			cursor: pointer;
			justify-content: center;
			align-items: center;
			color: #ffffff;

			/* Secondary/Rosemary */
			background: #3e505b;
			border-radius: 30px;
			font-family: "Inter";
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
			margin-top: 6px;
			text-align: center;
		}
		@include screen-max(900) {
			width: 100%;
		}
		overflow: hidden;
		scroll-behavior: smooth;
	}
	.title {
		font-family: "Akrobat";
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 10px;
		/* identical to box height */
		margin-top: 20px;
		text-align: center;
		text-transform: uppercase;
		color: #272727;
		text-align: center;
		text-transform: uppercase;
		color: #272727;
	}
	.container {
		width: 100%;
		padding: 0px 40px;
		@include screen-max(900) {
			padding: 0px 28px;
		}
		display: flex;
		flex-direction: column;

		.langBox {
			display: flex;
			width: 100%;
			height: 43px;
			margin-top: 20px;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			.boxLeft {
				display: flex;
				cursor: pointer;
				._desc {
					margin-left: 15px;
					.title {
						font-family: "Akrobat";
						font-style: normal;
						font-weight: 700;
						font-size: 20px;
						line-height: 25px;
						/* identical to box height */
						text-transform: uppercase;
						color: #232323;
					}
					.lang {
						font-family: "Inter";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 18px;
						/* identical to box height, or 129% */
						color: #232323;
					}
				}
			}
			.boxRight {
				display: flex;
				justify-content: space-between;
				cursor: pointer;
			}
			._icon {
				width: 100%;
				justify-content: center;
				display: flex;
				align-items: center;
				cursor: pointer;
				svg {
					vertical-align: middle;
					height: 31px !important;
					width: 42px !important;
				}
			}
		}
	}
	.content {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		/* or 138% */
		text-align: center;
		color: #272727;
	}
	._btnWrapper {
		height: 49px;
		margin: 0px auto;
		width: 360px;
		display: flex;
		justify-content: center;
		margin-top: 10px;
		align-items: center;
		align-content: center;
		background: #3e505b;
		border-radius: 24px;
		.__btn {
			text-align: center;
			font-family: "Inter";
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			color: #ffffff;
		}
	}
	&__or {
		margin-bottom: 40px;
		@media (max-width: 992px) {
			margin-bottom: 20px;
		}
	}
}
