.emptyListMessage {
	&__description {
		padding: 24px 0;
		margin: 0 auto;
		text-align: center;

		font-size: 1.4rem;
		line-height: 1.29;
		color: #232323;
		font-weight: normal;
	}
}
