.preloader {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 5;
	width: auto;
	height: auto;
	transform: translate(-50%, -50%);
}

.app--webview .preloader {
	display: none;
}
