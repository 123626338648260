@import "../../../base/styles/colors.scss";

.countrySelect {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding: 7px 12px;
	gap: 10px;
	cursor: pointer;
	width: 66px;
	height: 29px;
	/* Primary/Teal */
	border: 1px solid $link;
	border-radius: 30px;
	&__icon {
		width: 20px;
		height: 15px;
		img {
			width: 100%;
		}
	}

	&__lang {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		margin-left: -4.2px;
		/* identical to box height */
		text-align: center;
		/* Primary/Teal */
		color: $link;
	}
}
