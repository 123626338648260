.successPanel {
	overflow: hidden;
	color: #fff;
	font-size: 1.6rem;
	background: #000;

	&__inner {
		padding: 20px 40px;

		@media (max-width: 500px) {
			padding: 20px;
		}
	}

	&--closing {
		animation: collapse 0.25s ease forwards;
	}

	&__icon {
		min-width: 24px;
		height: 24px;
		margin-right: 15px;
		background: url(../../../img/icons/tick-black.svg) no-repeat #fff center / 42%
			auto;
		border-radius: 100%;
	}

	&__contents {
		flex-grow: 1;
	}

	&__close {
		width: 25px;
		height: 25px;
		border-radius: 100%;
		padding: 0 !important;
		margin-left: 30px;
		background: url(../../../img/icons/cross-black.svg) no-repeat #fff center /
			40% auto;
		border: 0;
		backface-visibility: hidden;
		cursor: pointer;

		&:focus,
		&:hover {
			outline: 0;
		}
	}
}

@keyframes collapse {
	0% {
		max-height: 75px;
	}

	100% {
		max-height: 0px;
	}
}
