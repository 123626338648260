.AuthHeader {
	&__icon {
		width: 80px;
		height: 80px;
	}

	&__newbie-icon {
		width: 36px;
		height: 36px;
	}

	&__forward-icon {
		width: 11px;
		height: 10px;
		margin-left: 10px;
		align-self: flex-end;
		margin-bottom: 5px;
	}

	&__text {
		color: #59baae;
		font-size: 1.6rem;
		width: 53px;
		display: inline-block;
		padding-top: 12px;
		margin: 0 5px;
		line-height: 1;
		font-weight: bold;
	}
}
