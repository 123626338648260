.yearMonthSelect {
  &__select {
    display: inline-block;
    width: auto;

    &:first-child {
      margin-right: 5px;
    }

  }

}
