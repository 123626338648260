.newToYM {
	padding: 4px 0;
	align-items: center;
	line-height: 1em;
	background: #8ab0ab;

	&,
	&:hover,
	&:active,
	&:focus {
		color: #fff;
	}

	&.row {
		margin: 0 0 21px;
	}

	&__img {
		width: 42px;
		height: 42px;
		margin-right: 11px;
		margin-bottom: 6px;

		@media (max-width: 767px) {
			width: 32px;
			height: 32px;
			margin-bottom: 0;
		}
	}

	&__title {
		margin-bottom: -4px;
		font-family: "Akrobat", Sans-Serif;
		font-size: 1.8rem;
		font-weight: bold;

		@media (max-width: 767px) {
			margin-bottom: 5px;
			text-align: center;
		}
	}

	&__register {
		text-align: right;

		@media (max-width: 767px) {
			text-align: center;
			margin-bottom: 10px;
		}
	}
}
