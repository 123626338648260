@import "../../base/styles/mixins/media-queries.scss";
@import "../../base/styles/colors";

.ymSearch {
	width: 100%;
	background: none;

	@media (max-width: 575px) {
		border-radius: 0px;
	}

	&__inner {
		width: 100%;
		height: 48px;
		padding: 4px;
		margin: 0;
		background: #fff;
		border-radius: 25px;

		@media (max-width: 575px) {
			padding: 10px;
		}
	}

	&__field {
		width: calc(100% - 50px);
		padding: 0 0 0 15px;
		background: none;
		border: 0;

		&,
		:active,
		:focus {
			outline: 0;
		}
	}

	input::placeholder {
		color: #9baca2 !important;
		opacity: 1;
	}

	&__btn {
		display: block;
		width: 40px;
		height: 40px;
		background: none;
		outline: none;
		border: none;

		&-icon {
			width: 18px;
			fill: none;
			stroke: #fff;
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-width: 2;
		}

		&:hover &-icon,
		&:active &-icon,
		&:hover:focus &-icon {
			stroke: $cta;
		}
	}
}
