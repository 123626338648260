.overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 90;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	animation: fade-in 0.3s linear forwards;

	&--closing {
		animation: fade-out 0.3s linear forwards;
	}

	&__inner {
		position: relative;
		width: 100%;
		max-height: 100%;
		padding: 80px 0;
		margin: 0 auto;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

		& > * {
			z-index: 2;
		}
	}

	&__backing {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
	}
}
