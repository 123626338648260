@import "../../base/styles/colors";

.button {
	align-items: center;
	justify-content: center;
	display: flex;
	width: 100%;
	height: 48px;
	font-size: 1.6rem;
	font-weight: bold;
	border: solid 2px;
	cursor: pointer;

	&__splitter {
		border-right: 1px solid #fff;
	}

	&--dotted {
		background: transparent !important;
		border: 1px dashed $cta !important;
		color: $cta !important;
	}

	&-inner {
		position: relative;
		width: 100%;
	}

	&--outline-none {
		outline: none;
		color: $link;
		border: 0;
		height: 30px !important;
		background: none;
		display: inline-block;
		font-weight: normal;
		width: auto;
	}

	&--outline-2 {
		outline: none;
		color: $link;
		background: #fff;
		border: 0.5px solid #59baae;
		border-radius: 2px;
		min-width: 76px;
		font-weight: bold;
		height: 30px !important;
		text-align: center;
		width: auto;
		height: auto;
		display: inline-block;
		font-size: 1.4rem;
		line-height: 1;

		&:hover,
		&:active {
			color: #fff;
			background: $link;
		}
	}

	&__btn-tab {
		&-content {
			font-family: "Akrobat", Sans-Serif;
			font-size: 1.4rem;
			font-weight: bold;
			width: 150px;
			img {
				margin-right: 10px;
			}

			&.left {
				border-top-right-radius: 0 !important;
				border-bottom-right-radius: 0 !important;
				position: relative;
				&:hover:before {
					opacity: 0;
				}
				&:before {
					width: 1px;
					height: 60%;
					top: 20%;
					right: -4px;
					content: "";
					position: absolute;
					background: #fff;
					opacity: 1;
					z-index: 1;
				}
			}
			&.right {
				border-top-left-radius: 0 !important;
				border-bottom-left-radius: 0 !important;
			}
		}
	}

	&:focus,
	&:active {
		outline: 0;
	}

	&--disabled {
		color: #fff;
		background: #ccc;
		border-color: #ccc;
		pointer-events: none;
	}

	&:active &-inner {
		transform: translateY(1px);
		transition-duration: 0.05s;
	}

	&--default {
		color: #fff;
		background: $cta;
		border-color: $cta;

		&:hover,
		.button-parent-hover:hover &,
		&:active {
			color: $cta;
			background: #fff;
			border-color: $cta;
		}

		&.button--disabled {
			color: #e5e5e5;
			background: #ccc;
			border-color: #ccc;
		}
	}

	&--black {
		color: #fff;
		background: #000;
		border-color: #000;

		&:hover,
		.button-parent-hover:hover &,
		&:active {
			color: #000;
			background: #fff;
		}

		&.button--disabled {
			color: #787878;
			border-color: #000;
		}
	}

	&--outline {
		color: $cta;
		background: transparent;
		border-color: $cta;

		&:hover,
		.button-parent-hover:hover &,
		&:active {
			color: #fff;
			background: $cta;
			border-color: $cta;
		}

		&.button--disabled {
			color: #ccc;
			border-color: #ccc;
		}
	}

	&--blue {
		color: #fff;
		background: $mariner;
		border-color: $mariner;

		&:hover,
		.button-parent-hover:hover &,
		&:active {
			color: $mariner;
			background: #fff;
			border-color: $mariner;
		}

		&.button--disabled {
			color: #e5e5e5;
			background: #ccc;
			border-color: #ccc;
		}
	}

	&--green {
		color: #fff;
		background: #8ab0ab;
		border-color: #8ab0ab;

		&:hover,
		.button-parent-hover:hover &,
		&:active {
			color: #8ab0ab;
			background: #fff;
			border-color: #8ab0ab;
		}

		&.button--disabled {
			color: #e5e5e5;
			background: #ccc;
			border-color: #ccc;
		}
	}

	&--black-outline {
		color: #000;
		background: transparent;
		border-color: #000;

		&:hover,
		.button-parent-hover:hover &,
		&:active {
			color: #fff;
			background: #000;
			border-color: #000;
		}

		&.button--disabled {
			color: #ccc;
			border-color: #ccc;
		}
	}

	&--gray-outline {
		color: $warm-grey;
		background: transparent;
		border-color: $warm-grey;

		&:hover,
		.button-parent-hover:hover &,
		&:active {
			color: #fff;
			background: #000;
			border-color: #000;
		}

		&.button--disabled {
			color: #ccc;
			border-color: #ccc;
		}
	}

	.button-parent-hover:hover &.button--disabled {
		color: #ccc;
		background: #fff;
		border-color: #ccc;
	}
}
