.footerSocial {
	display: flex;
	align-items: center;
	margin-top: 20px;
	@media (min-width: 993px) {
		margin-left: auto;
	}
	&__icon {
		&--facebook {
			background: url(../../../img/ui/logo-facebook-green.svg) no-repeat center /
				contain;
		}

		&--instagram {
			background: url(../../../img/ui/logo-instagram-green.svg) no-repeat center /
				contain;
		}
	}
}
