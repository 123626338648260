@import "../../../base/styles/colors";

.HeaderNavItem {
	position: relative;
	font-size: 1.8rem;

	@media (min-width: 993px) {
		padding-right: 23px !important;
	}

	&--has-children {
		transition: all ease 0.2s;
		&:hover {
			@media (min-width: 993px) {
			}
		}
	}

	@media (max-width: 992px) {
		font-size: 2.4rem;

		&.list-inline-item {
			display: block;
			width: 100%;
		}

		&--has-children {
			background: url(../../../img/icons/chevron-down-teal.svg) no-repeat
				calc(100% - 25px) 17px / 15px auto;
		}

		&--has-children.HeaderNavItem__link--active {
			background: url(../../../img/icons/chevron-up-teal.svg) no-repeat
				calc(100% - 25px) 17px / 15px auto;
		}
	}

	&__link {
		position: relative;
		color: #232323;
		font-family: "Akrobat", Sans-Serif;

		@media (max-width: 992px) {
			padding: 6px 0;
		}

		&::before,
		&::after {
			z-index: -1;
			position: absolute;
			display: block;
			width: 100%;
			content: "";

			@media (max-width: 992px) {
				content: none;
			}
		}

		&--active {
			font-weight: bold;

			&::after {
				background: $cta;
			}
		}

		&:before {
			width: 100%;
			height: 100%;
			background-color: transparent;
			box-sizing: content-box;

			top: -10px;
			left: -15px;
			padding: 10px 15px;
			padding-right: 25px;

			animation: fade-in 0.3s linear, from-top 0.2s ease-out;

			.HeaderNavItem--has-children:hover & {
				background-color: #fff;
			}

			.headerNavUser__item--user & {
				opacity: 0;
			}
		}

		&::after {
			top: 100%;
			height: 2px;
			pointer-events: none;

			.headerNavUser__item--user & {
				opacity: 0;
			}
		}

		&:hover,
		&:active {
			color: $cta;
		}

		&--active,
		&--active:focus,
		.HeaderNavItem--active & {
			color: $cta;
		}

		&--active {
			.headerNavMain__sub {
				@media (max-width: 992px) {
					display: block;
				}
			}
		}

		@media (max-width: 992px) {
			.HeaderNavItem--has-children > & {
				pointer-events: none;
			}
		}
	}

	.headerNavMain__sub {
		font-weight: normal;

		&-link--active {
			font-weight: bold;
		}
	}

	&:hover &__link::after {
		background: $cta;
	}

	&__badge {
		position: absolute;
		top: 3px;
		right: 0;
		width: 9px;
		height: 9px;
		background: $orange;

		@media (max-width: 992px) {
			position: static;
		}
	}
}
