@import "../../base/styles/colors";

.plainButton {
  display: inline-block;
  color: $link;
  font-size: inherit;
  background: none;
  border: 0;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $cta;
    outline: 0;
  }

  &--disabled {
    pointer-events: none;
  }

  &:active &-inner {
    transform: translateY(1px);
    transition-duration: 0.05s;
  }

}
