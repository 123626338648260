.newPasswordModal {
  &__tip {
    margin-bottom: 20px;
    color: #999;
  }

  &__error {
    margin: 20px 0 -10px;
  }

  &__save.auth__btn {
    max-width: 240px;
    margin: 0 auto 10px;
  }

}
