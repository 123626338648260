@import '../../../base/styles/colors';

/** most styles are inherited from Authentication.scss in parent to maintain consistent look & feel */
.connectModal {
  &__or {
    margin-bottom: 40px;

    @media (max-width:992px) {
      margin-bottom: 20px;
    }

  }

}
